@import url("https://fonts.googleapis.com/css?family=Work+Sans:200,300,400,500,600,700&subset=latin-ext");

body {
  min-height: 100vh;
  font-family: "Work Sans", sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ul,
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    padding: 0;
  }

  a {
    text-decoration: none !important;
  }

  .ck-editor-text {
    p {
      margin-top: 20px;
    }

    ul,
    ol {
      padding-left: 20px;
    }

    img {
      max-width: 100% !important;
    }

    *:nth-child(1) {
      margin-top: 0;
    }
  }

  .btn {
    padding: 12px 32px;
    font-size: 13px;
    text-transform: uppercase;
    transition: all 0.4s;

    &.btn-default {
      border: 1px solid @color1;
      background: @color1;
      color: #fff;

      &:hover {
        background: #fff;
        color: #333;
      }
    }
  }

  .m-t-5 {
    margin-top: 5px;
  }
  .m-t-10 {
    margin-top: 10px;
  }
  .m-t-15 {
    margin-top: 15px;
  }
  .m-t-20 {
    margin-top: 20px;
  }
  .m-t-25 {
    margin-top: 25px;
  }
  .m-t-30 {
    margin-top: 30px;
  }
  .m-b-5 {
    margin-bottom: 5px;
  }
  .m-b-10 {
    margin-bottom: 10px;
  }
  .m-b-15 {
    margin-bottom: 15px;
  }
  .m-b-20 {
    margin-bottom: 20px;
  }
  .m-b-25 {
    margin-bottom: 25px;
  }
  .m-b-30 {
    margin-bottom: 30px;
  }
  .p-t-5 {
    padding-top: 5px;
  }
  .p-t-10 {
    padding-top: 10px;
  }
  .p-t-15 {
    padding-top: 15px;
  }
  .p-t-20 {
    padding-top: 20px;
  }
  .p-t-25 {
    padding-top: 25px;
  }
  .p-t-30 {
    padding-top: 30px;
  }
  .p-b-5 {
    padding-bottom: 5px;
  }
  .p-b-10 {
    padding-bottom: 10px;
  }
  .p-b-15 {
    padding-bottom: 15px;
  }
  .p-b-20 {
    padding-bottom: 20px;
  }
  .p-b-25 {
    padding-bottom: 25px;
  }
  .p-b-30 {
    padding-bottom: 30px;
  }
}

.system-alerts {
  width: 100%;
  text-align: center;

  .alert {
    border-radius: 0;
    border: none;
    height: 60px;
    display: flex;
    align-items: center;
    opacity: 0.9;
    transition: all 0.4s;

    .alert-inner {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .img-hidden {
        visibility: hidden;
        opacity: 0;
        height: 18px;
      }

      p {
        color: #fff;
        padding: 0 50px;
        font-family: 15px;
      }

      button {
        color: #fff;
        opacity: 1;
        font-weight: 300;
        float: none;

        img {
          height: 18px;
        }
      }
    }

    &.alert-success {
      background: #76ad21;
    }

    &.alert-info {
      background: #218ead;
    }

    &.alert-warning {
      background: #c52b20;
    }
  }
}

.breadcrumb {
  background: none;
  padding: 0;
  margin: 20px 0;

  li {
    font-size: 12px;
    font-weight: 300;

    a {
      color: @color1;
    }
  }
}

#return-to-top {
  position: fixed;
  z-index: 333;
  bottom: 20px;
  right: 20px;
  height: 45px;
  width: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: @color1;
  visibility: hidden;
  opacity: 0;
  transition: all 350ms;

  img {
    width: 30px;
  }

  &.active {
    opacity: 1;
    visibility: visible;
  }

  &:hover {
    transform: scale(1.05);
  }
}

div.section-header {
  .navbar {
    min-height: auto;
    margin-bottom: 0;
    background: none;
    border: none;
    border-radius: 0;
    padding-top: 30px;

    .container {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &:before,
      &:after {
        content: none;
      }
    }

    .navbar-brand {
      display: flex;
      align-items: center;
      padding: 0 0 0 15px;
      height: auto;

      img {
        height: 65px;
      }
    }

    .navbar-collapse {
      padding-left: 0;
      padding-right: 0;

      .navbar-nav {
        li {
          a {
            padding: 0 15px;
            border: none;
            background: none;
            text-transform: uppercase;
            color: #414141;
            font-size: 14px;
            font-weight: 300;
            display: flex;
            align-items: center;
            justify-content: center;
            outline: 0;
            transition: all 0.3s;
          }
        }
      }
    }

    .navbar-contact {
      list-style: none;
      display: flex;

      li {
        margin-right: 30px;

        a {
          color: @color1;
          font-weight: 600;
          display: flex;
          align-items: center;

          img {
            margin-right: 10px;
            height: 17px;
          }
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .navbar-toggle {
      border: none;
      margin-right: 10px;
      background: none;

      .toggle-bar {
        background: #000;
        height: 2px;
        transition: all 0.4s;
        margin-bottom: 5px;
        display: block;
        width: 30px;

        &:last-of-type {
          margin-bottom: 0;
        }
      }

      &.collapsed {
        .toggle-bar {
          &:nth-of-type(2) {
            width: 20px;
          }

          &:nth-of-type(3) {
            width: 25px;
          }

          &:nth-of-type(4) {
            width: 30px;
          }
        }
      }
    }
  }

  .navbar-extra {
    margin-top: 30px;
    padding: 30px 10px;
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: space-between;
    position: relative;

    .btn-close {
      position: absolute;
      top: -16px;
      left: 50%;
      transform: translateX(-50%);
      height: 25px;
      width: 25px;
      border-radius: 50%;
      background: #fff;
      padding: 5px;

      img {
        width: 100%;
      }
    }

    .extra-service {
      display: flex;
      align-items: center;
      justify-content: space-between;

      img {
        width: 60px;
      }

      .service-description {
        padding-left: 20px;

        p {
          color: #333;
          font-weight: 300;
        }
      }
    }
  }
}

div.section-slogan {
  margin-top: 30px;
  height: 200px;
  display: flex;
  align-items: center;
  background: url("../img/backgrounds/fotolia_184384184.jpg") center;
  background-size: cover;

  .heading {
    font-weight: 300;
    font-size: 32px;
    color: #fff;
    text-shadow: 2px 2px 15px rgba(0, 0, 0, 0.5);

    strong {
      font-size: 50px;
    }
  }
}

div.section-main-carousel {
  padding: 50px 0;

  .owl-carousel {
    .owl-controls {
      .owl-nav {
        display: none;
      }

      .owl-dots {
        margin-top: 50px;
        display: flex;
        justify-content: space-between;
        padding: 0 200px;

        .owl-dot {
          padding: 10px 0;
          margin: 0 2px;
          display: block;
          width: 18%;

          span {
            display: block;
            width: 100%;
            height: 2px;
            border-radius: 0;
            margin: 0;
          }

          &.active {
            span {
              background: @color1;
            }
          }
        }
      }
    }
  }

  .item-inner {
    display: flex;
    justify-content: space-between;

    .inner-image {
      width: 28%;
      position: relative;

      .image-ring {
        border: 3px solid @color1;
        -webkit-border-radius: 30px;
        height: 18px;
        width: 18px;
        position: absolute;
        z-index: 111;
        -webkit-animation: pulsate 2s ease-out;
        -webkit-animation-iteration-count: infinite;
        opacity: 0;
      }

      @-webkit-keyframes pulsate {
        0% {
          -webkit-transform: scale(0.1, 0.1);
          opacity: 0;
        }
        50% {
          opacity: 0.4;
        }
        100% {
          -webkit-transform: scale(1.2, 1.2);
          opacity: 0;
        }
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .inner-description {
      width: 50%;
      text-align: center;
      align-self: center;

      .heading {
        font-weight: 300;
        font-size: 32px;

        strong {
          font-size: 50px;
        }
      }

      .description-service {
        margin-top: 50px;

        h2 {
          color: #fff;
          font-weight: 700;
          font-size: 28px;
          margin-bottom: 30px;
          background: @color1;

          a {
            display: block;
            padding: 30px;
            color: #fff;
          }
        }

        p {
          color: #444;
          font-weight: 300;
        }

        hr {
          border-top: 1px solid rgba(0, 0, 0, 0.2);
        }

        .btn-more {
          font-weight: 700;
          font-size: 12px;
          color: #444;
          text-transform: uppercase;
          display: block;
          margin-top: 30px;
        }
      }
    }
  }
}

div.section-offer-element {
  padding: 70px 0;
  background-color: #fff;

  &.bg-light {
    background: linear-gradient(-45deg, #fff, #f3f6f7);
    .offer-flexbox {
      flex-direction: row-reverse;
    }
  }

  .offer-flexbox {
    display: flex;
    justify-content: space-around;

    .service-image {
      width: 50%;
      display: flex;
      justify-content: center;

      img {
        transition: all 0.4s;
        height: 350px;
      }
    }

    .service-description {
      width: 45%;

      h2 {
        margin: 20px 0;
        color: @color1;
        font-weight: 600;
        font-size: 36px;
      }

      hr {
        border-top: 1px solid rgba(0, 0, 0, 0.3);
      }

      p {
        color: #444;
        font-weight: 300;
      }

      .btn-more {
        font-weight: 700;
        font-size: 12px;
        color: #444;
        text-transform: uppercase;
        display: block;
        margin-top: 20px;
      }
    }

    &:hover {
      .service-image {
        img {
          transform: scale(1.04);
        }
      }
    }
  }
}

div.section-offer {
  padding: 70px 0;
  background: #fff; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    -45deg,
    #fff,
    #f3f6f7
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    -45deg,
    #fff,
    #f3f6f7
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  .offer-flexbox {
    display: flex;
    justify-content: space-between;

    .flexbox-service {
      width: 27%;
      text-align: center;

      .service-image {
        img {
          width: 100%;
          transition: all 0.4s;
        }
      }

      .service-description {
        h2 {
          margin: 20px 0;
          color: @color1;
          font-weight: 600;
          font-size: 28px;
        }

        p {
          color: #444;
          font-weight: 300;
        }

        .btn-more {
          font-weight: 700;
          font-size: 12px;
          color: #444;
          text-transform: uppercase;
          display: block;
          margin-top: 20px;
        }
      }

      &:hover {
        .service-image {
          img {
            transform: scale(1.04);
          }
        }
      }
    }
  }
}

div.section-about {
  padding: 50px 0;

  .anniversary-flexbox {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .flexbox-image {
      width: 25%;
      position: relative;

      p {
        position: absolute;
        color: #fff;
        font-weight: 700;
        text-align: center;
        left: 50%;
        top: 65px;
        line-height: 20px;
        transform: translateX(-50%);
        font-size: 16px;

        strong {
          font-size: 30px;
        }
      }

      img {
        width: 100%;
      }
    }

    .flexbox-description {
      width: 75%;
      padding-left: 80px;

      h2 {
        font-weight: 300;
        font-size: 32px;
        margin-bottom: 30px;

        strong {
          font-size: 50px;
          color: @color1;
        }
      }

      p {
        color: #444;
        font-weight: 300;
      }
    }
  }

  .about-flexbox {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .flexbox-description {
      width: 32%;

      h2 {
        font-weight: 300;
        font-size: 32px;
        margin-bottom: 30px;

        strong {
          font-size: 50px;
          color: @color1;
        }
      }

      p {
        color: #444;
        font-weight: 300;
      }

      hr {
        border-top: 1px solid rgba(0, 0, 0, 0.2);
      }

      .btn-more {
        font-weight: 700;
        font-size: 12px;
        color: #444;
        text-transform: uppercase;
        display: block;
        margin-top: 30px;
      }
    }

    .flexbox-images {
      width: 48%;
      padding-top: 50%;
      position: relative;
    }
  }
}

.certifications-flexbox {
  .circle-image {
    position: absolute;
    border-radius: 50%;
    display: flex;
    align-items: center;
    overflow: hidden;
    border: 7px solid #fff;

    img {
      width: 100%;
    }

    &.image-lg {
      width: 300px;
      height: 300px;
    }

    &.image-md {
      width: 210px;
      height: 210px;
    }

    &.image-sm {
      width: 120px;
      height: 120px;
    }
  }
}

div.okna-pcv-section-content,
div.aluminium-section-content {
  padding-bottom: 50px;

  .content-heading {
    text-align: center;

    p {
      font-size: 18px;
      font-weight: 300;
      color: @color1;
    }
  }

  .content-navigation {
    padding-top: 50px;
    display: flex;
    justify-content: space-between;

    li {
      border-top: 1px solid rgba(0, 0, 0, 0.2);
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);
      width: calc(100% / 3);
      text-align: center;

      a {
        display: block;
        padding: 30px 10px;
        font-weight: 300;
        color: #333;
      }

      &.active {
        padding: 30px 10px;
        color: @color1;
        font-weight: 700;
        border-bottom: 1px solid @color1;
      }
    }
  }

  .content-offer {
    ul {
      width: 24%;
    }
  }

  .ck-editor-text {
    margin-top: 50px;

    p {
      font-size: 15px;
      font-weight: 300;
    }

    ul {
      padding-left: 20px;

      li {
        font-size: 15px;
        font-weight: 300;
      }
    }

    .btn-download {
      display: block;
      font-weight: 600;
      text-transform: uppercase;
      color: #222;
      margin-top: 10px;
      transition: color 350ms;
      &:hover {
        color: @color1;
      }
    }
  }

  .offer-image {
    margin-top: 50px;
    height: 300px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}

.window-offer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  ul {
    margin-top: 30px;
    text-align: center;
    list-style: none;

    // &:nth-of-type(1) {
    //   text-align: left;
    // }

    li {
      p {
        margin-top: 20px;
        font-size: 15px;
        font-weight: 300;

        b {
          font-size: 12px;
        }
      }

      img {
        margin: 0 auto;
      }

      .stars {
        display: flex;
        justify-content: center;

        span {
          color: #ff9d11;
          margin: 5px;
          display: block;
          font-size: 16px;
        }
      }
    }
  }
}

div.oferta-section-content {
  .heading-wrapper {
    margin-top: 50px;
    padding: 30px 0;
    background: #fff; /* fallback for old browsers */
    background: -webkit-linear-gradient(
      -45deg,
      #fff,
      #f3f6f7
    ); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(
      -45deg,
      #fff,
      #f3f6f7
    ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  }

  .content-heading {
    text-align: center;

    h2 {
      font-weight: 300;
      font-size: 32px;

      strong {
        font-size: 50px;
        color: @color1;
      }
    }

    p {
      font-size: 15px;
      font-weight: 300;

      strong {
        color: @color1;
      }
    }
  }

  .content-others {
    .others-flexbox {
      display: flex;
      justify-content: space-around;
      padding: 30px 0;
    }
  }

  .content-flexbox {
    margin-top: 50px;
    display: flex;
    justify-content: space-between;

    .flexbox-image {
      width: 28%;

      img {
        width: 100%;
      }
    }

    .flexbox-description {
      width: 50%;

      .heading {
        font-weight: 300;
        font-size: 32px;

        strong {
          font-size: 50px;
        }
      }

      p {
        margin-top: 20px;
        font-size: 15px;
        font-weight: 300;

        strong {
          color: @color1;
        }
      }

      ul {
        li {
          p {
            margin-top: 20px;
            font-size: 15px;
            font-weight: 300;

            b {
              font-size: 12px;
            }
          }

          .stars {
            display: flex;

            span {
              color: #ff9d11;
              margin: 5px;
              display: block;
              font-size: 16px;
            }
          }
        }
      }
    }
  }

  .window-offer {
    ul {
      width: 35%;
    }
  }
}

.section-offer-cta {
  padding: 30px 0;
  margin-top: 50px;

  .cta-wrapper {
    border-top: 1px solid rgba(#000, 0.2);
    padding-top: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      .cta-image {
        img {
          transform: scale(1.04);
        }
      }
    }
  }

  .cta-description {
    width: 50%;

    h2 {
      font-weight: 300;
      font-size: 28px;
      line-height: 40px;

      span {
        font-size: 16px;
        color: @color1;
        font-weight: 600;
      }
    }

    .btn-more {
      font-weight: 700;
      font-size: 12px;
      color: #444;
      text-transform: uppercase;
      display: block;
      margin-top: 20px;
    }
  }

  .cta-image {
    width: 35%;
    height: 320px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      transition: all 350ms;
    }
  }
}

div.aktualnosci-section-content {
  .content-heading {
    text-align: center;

    h2 {
      font-weight: 300;
      font-size: 32px;

      strong {
        font-size: 50px;
        color: @color1;
      }
    }

    p {
      font-size: 15px;
      font-weight: 300;

      b {
        font-size: 12px;
      }
    }
  }

  .news-flexbox {
    margin-top: 20px;
  }
}

.news-flexbox {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .flexbox-service {
    margin-top: 30px;
    width: 32%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    background: #fff;
    position: relative;

    header {
      width: 100%;

      .service-image-wrapper {
        width: 100%;
        padding-top: 55%;
        position: relative;

        .service-image {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          border-radius: 5px;
          display: flex;
          align-items: center;
          overflow: hidden;

          img {
            width: 100%;
          }
        }
      }

      .name {
        margin-top: 15px;
        font-weight: 300;
        font-size: 15px;
        color: #333;
      }

      .small {
        margin-top: 5px;
        font-weight: 300;
        color: @color1;
      }
    }

    footer {
      margin-top: 15px;

      .btn-more {
        font-weight: 700;
        font-size: 12px;
        color: #444;
        text-transform: uppercase;
        display: block;
        color: @color1;
      }
    }

    &.service-hidden {
      margin: 0;
      padding: 0;
      visibility: hidden;
    }
  }
}

.pagination-nav {
  margin: 45px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  li {
    margin: 5px;

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      height: 35px;
      width: 35px;
      border-radius: 4px;
      color: #333;
      font-weight: 300;
    }

    &.active {
      a {
        background: @color1;
        color: #fff;
      }
    }
  }
}

div.static-page-section-content {
  padding-bottom: 50px;

  .content-flexbox {
    display: flex;
    justify-content: space-between;

    .flexbox-sidebar {
      width: 22%;

      .sidebar-configurator {
        text-align: center;
        padding-bottom: 20px;
        display: block;

        p {
          border-radius: 5px;
          border: 1px solid @color1;
          padding: 20px 0;
          font-size: 20px;
          font-weight: 300;
          padding: 10px;
          color: @color1;
        }

        img {
          margin-top: 20px;
          width: 100%;
          padding: 0 20px;
        }
      }
    }

    .flexbox-body {
      width: 70%;

      .ck-editor-text {
        h2 {
          font-weight: 300;
          font-size: 32px;

          strong {
            font-size: 50px;
            color: @color1;
          }
        }

        p {
          font-size: 15px;
          font-weight: 300;

          b {
            font-size: 12px;
          }
        }

        ul {
          padding-left: 20px;

          li {
            font-size: 15px;
            font-weight: 300;
          }
        }
      }

      .image-gallery {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-bottom: 20px;

        .gallery-service {
          width: 23%;
          margin-top: 20px;

          .service-image-wrapper {
            width: 100%;
            padding-top: 50%;
            position: relative;

            .service-image {
              position: absolute;
              top: 0;
              left: 0;
              height: 100%;
              width: 100%;
              display: flex;
              align-items: center;
              border-radius: 5px;
              overflow: hidden;

              img {
                width: 100%;
              }
            }
          }

          &.service-hidden {
            margin: 0;
            padding: 0;
            visibility: hidden;
          }
        }
      }
    }
  }

  .section-cta {
    padding-top: 80px;
    text-align: center;

    h2 {
      font-weight: 300;
      font-size: 32px;
      line-height: 44px;
      span {
        font-size: 22px;
        color: @color1;
        font-weight: 500;
      }
    }

    .btn-more {
      font-weight: 700;
      font-size: 12px;
      color: #444;
      text-transform: uppercase;
      display: block;
      margin-top: 20px;
    }
  }

  &.o-firmie {
    .logo-flexbox {
      margin: 50px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .flexbox-service {
        width: 22%;
      }
    }

    .certifications-flexbox {
      margin: 50px 0 70px 150px;
      width: 70%;
      padding-top: 60%;
      position: relative;
    }
  }

  &.aktualnosc {
    .main-image {
      width: 100%;
      border-radius: 8px;
    }

    .ck-editor-text {
      margin-top: 40px;
    }
  }
}

div.konfigurator-section-content {
  padding-bottom: 50px;

  .content-heading {
    text-align: center;

    h2 {
      font-weight: 300;
      font-size: 32px;

      strong {
        font-size: 50px;
        color: @color1;
      }
    }

    p {
      font-size: 15px;
      font-weight: 300;

      b {
        font-size: 12px;
      }
    }
  }

  .flexbox-form {
    margin-top: 30px;

    label {
      text-transform: uppercase;
      font-size: 12px;
      color: #555;
    }

    input {
      box-shadow: none;
      border: 1px solid #bbb;
      border-radius: 4px;
      height: 40px;

      &.file {
        height: auto;
        border: none;
      }
    }

    select {
      display: block;
      box-shadow: none;
      border: 1px solid #bbb;
      border-radius: 4px;
      height: 40px;
      width: 100%;
      padding-left: 15px;
      font-weight: 300;
    }

    textarea {
      box-shadow: none;
      border: 1px solid #bbb;
      border-radius: 4px;
    }

    .btn-more {
      padding: 0;
      background: none;
      border: none;
      font-weight: 700;
      font-size: 12px;
      color: #444;
      text-transform: uppercase;
      display: block;
      color: @color1;
    }
  }

  .information {
    margin: 5px 0 15px 0;

    p {
      font-size: 13px;
      color: @color1;

      a {
        font-weight: 600;
        color: @color1;
      }
    }
  }

  .type-flexbox {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .flexbox-service {
      margin-top: 30px;
      width: 48%;
      cursor: pointer;

      input {
        visibility: hidden;
        position: absolute;
        box-shadow: none;
        border: 1px solid #bbb;
        border-radius: 4px;
        height: 25px;
      }

      .service-inner {
        border: 1px solid @gray-lighter;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        transition: all 0.3s;
        padding: 5px;
        position: relative;

        .choose {
          position: absolute;
          font-size: 10px;
          text-transform: uppercase;
          color: #b1b1b1;
          top: -20px;
          left: 50%;
          transform: translateX(-50%);
          transition: all 0.3s;

          &:before {
            content: "nie";
          }
        }

        img {
          width: 48%;
        }

        .inner-text {
          width: 48%;

          .name {
            color: #333;
            font-size: 22px;
            font-weight: 300;
            transition: all 0.3s;
            margin-bottom: 10px;
          }

          .input-type {
            text-transform: uppercase;
            font-size: 12px;
            font-weight: 500;
            color: #555;
            text-align: left;
          }

          .input-visible {
            visibility: visible;
            position: relative;
            padding-left: 10px;
            width: 100%;
          }
        }
      }
    }

    .flexbox-service > input:checked + .service-inner {
      border: 1px solid #3cacf3;

      .choose {
        color: #3cacf3;

        &:before {
          content: none;
        }
      }
    }
  }

  .select-wrapper {
    margin-top: 15px;

    label {
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 500;
      color: #555;
      text-align: left;
    }

    select {
      display: block;
      width: 100%;
      height: 40px;
      border: 1px solid #bbb;
      border-radius: 4px;
      padding-left: 10px;
    }
  }

  .checkbox-service {
    margin-top: 15px;
    margin-bottom: 0;
    cursor: pointer;
    width: 100%;
    display: block;

    .service-inner {
      display: flex;
      align-items: center;

      .name {
        font-weight: 400;
        text-align: left;
        font-size: 15px;
      }

      &:before {
        content: "";
        display: block;
        margin-right: 12px;
        height: 16px;
        min-width: 16px;
        max-width: 16px;
        background: url("../img/pictures/success.svg") center;
        transition: all 0.4s;
      }
    }

    input {
      visibility: hidden;
      position: absolute;
    }
  }

  .checkbox-service > input:checked + .service-inner {
    &:before {
      background: url("../img/pictures/success-fill.svg") center;
    }
  }

  .btn {
    margin-top: 30px;
    width: 100%;
  }
}

div.kontakt-section-content {
  padding-bottom: 50px;

  .content-heading {
    text-align: center;

    h2 {
      font-weight: 300;
      font-size: 32px;

      strong {
        font-size: 50px;
        color: @color1;
      }
    }

    p {
      font-size: 15px;
      font-weight: 300;

      b {
        font-size: 12px;
      }
    }
  }

  .content-flexbox {
    margin-top: 50px;
    display: flex;
    justify-content: space-between;

    .flexbox-service {
      width: 45%;

      h2 {
        font-size: 32px;
        color: @color1;
      }

      .address {
        margin-top: 20px;
        font-size: 24px;
        font-weight: 300;
      }

      .hours {
        margin-top: 15px;
        font-size: 18px;
        font-weight: 300;
      }

      .phone {
        color: @color1;
        font-weight: 600;
        display: flex;
        align-items: center;
        font-size: 25px;

        img {
          margin-right: 10px;
          height: 17px;
        }
      }
    }

    .flexbox-form {
      width: 50%;

      label {
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 300;
        color: #999;
      }

      input {
        box-shadow: none;
        border: 1px solid #e4e4e4;
        border-radius: 4px;
        height: 40px;
      }

      textarea {
        box-shadow: none;
        border: 1px solid #e4e4e4;
        border-radius: 4px;
      }

      .btn-more {
        padding: 0;
        background: none;
        border: none;
        font-weight: 700;
        font-size: 12px;
        color: #444;
        text-transform: uppercase;
        display: block;
        color: @color1;
      }
    }
  }

  .content-cities {
    margin-top: 70px;
    display: flex;
    align-items: center;
    justify-content: center;

    .cities-image {
      min-width: 400px;
      max-width: 400px;

      img {
        width: 100%;
      }
    }

    .cities-description {
      padding-left: 100px;

      h2 {
        font-size: 26px;
        font-weight: 300;
      }

      p {
        font-size: 15px;
        font-weight: 300;

        b {
          font-size: 12px;
        }
      }
    }
  }

  iframe {
    width: 100%;
    margin-top: 70px;
    height: 300px;
    border: none;
  }
}

div.section-footer {
  padding-top: 70px;
  background: #fff; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    -45deg,
    #fff,
    #f3f6f7
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    -45deg,
    #fff,
    #f3f6f7
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  .footer-flexbox {
    display: flex;
    justify-content: space-between;

    .flexbox-service {
      .small-heading {
        color: #999;
        font-weight: 300;
        margin-bottom: 10px;
      }

      .phone {
        color: @color1;
        font-weight: 600;
        display: flex;
        align-items: center;
        font-size: 25px;

        img {
          margin-right: 10px;
          height: 17px;
        }
      }

      ul {
        margin: 10px 0;
        li {
          padding: 3px 0;
          a {
            font-size: 16px;
            color: @color1;
            font-weight: 300;
          }
        }
      }
    }
  }

  .footer-credits {
    margin-top: 70px;
    padding: 30px 0;
    background: #fff;

    .alert {
      background: none;
      border-radius: 0;
      border: none;
      padding: 0;
      margin-bottom: 20px;
      padding-bottom: 20px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);

      p {
        font-weight: 300;
        color: #999;
        font-size: 12px;

        a {
          color: @color1;
        }
      }
    }

    .credits-info {
      p {
        color: #999;
        font-weight: 300;
        font-size: 13px;

        a {
          color: @color1;
        }
      }
    }
  }
}
