@media (max-width: @screen-md-max) {
	div.section-about {
		.anniversary-flexbox {
			margin-bottom: 40px;

			.flexbox-image {
				p {
					top: 52px;
				}
			}
		}
    }
}

@media (max-width: @screen-sm-max) {
	div.section-header {
		.navbar {
			.container {
				flex-wrap: wrap;
			}

			.navbar-contact {
				margin-top: 30px;
				width: 100%;

				li {
					width: 100%;

					a {
						padding: 10px;
						border-radius: 4px;
						box-shadow: 0px 0px 1px 0px @color1;
						justify-content: center;
					}
				}
			}
		}
	}

	div.section-slogan {
		text-align: center;

		.container {
			width: 100%;
		}

		.heading {
			font-size: 28px;

			strong {
				font-size: 36px;
			}
		}
	}

	div.section-offer {
		.offer-flexbox {
			.flexbox-service {
				.service-description {
					h2 {
						font-size: 22px;
					}
				}
			}
		}
    }

    .section-offer-cta {
        .cta-description {

            h2 {
                font-size: 20px;
                line-height: 30px;
    
                span {
                    font-size: 13px;
                }
            }
        }
    
        .cta-image {
            height: 250px;
        }
    }

	div.section-about {
		.anniversary-flexbox {
			.flexbox-image {
				p {
					top: 28%;
				}
			}
		}

		.about-flexbox {
			flex-direction: column-reverse;

			.flexbox-description {
				margin-top: 50px;
				width: 100%;
			}

			.flexbox-images {
				width: 100%;
				padding-top: 0;
				position: relative;
			}
		}
	}

	.certifications-flexbox {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;

		.circle-image {
			margin-top: 7px;
			position: relative;
			border-radius: 5px;
			border: none;
			top: auto !important;
			left: auto !important;

			&.image-lg {
				width: 32.5%;
				height: 150px;
			}

			&.image-md {
				width: 32.5%;
				height: 150px;
			}

			&.image-sm {
				width: 32.5%;
				height: 150px;
			}
		}
	}

	.news-flexbox {
		.flexbox-service {
			width: 48%;
		}
	}

	div.static-page-section-content {
		.content-flexbox {
			.flexbox-sidebar {
				display: none;
			}

			.flexbox-body {
				width: 100%;

				.image-gallery {
					.gallery-service {
						width: 48%;
					}
				}
			}
		}

		&.o-firmie {
			.certifications-flexbox {
				margin: 30px 0;
				width: 100%;
				padding-top: 0;
			}
		}
	}

	div.konfigurator-section-content {
		.type-flexbox {
			.flexbox-service {
				margin-top: 40px;
				width: 100%;

				.service-inner {
					flex-direction: column;
					align-items: flex-start;
					padding: 0;

					img {
						width: 100%;
						max-width: 200px;
					}

					.inner-text {
						width: 100%;
						padding: 0 30px 30px 30px;
					}
				}
			}
		}
	}

	div.kontakt-section-content {
		padding-bottom: 50px;

		.content-flexbox {
			flex-direction: column;

			.flexbox-service {
				width: 100%;
				text-align: center;

				.phone {
					justify-content: center;
				}
			}

			.flexbox-form {
				margin-top: 50px;
				width: 100%;
			}
		}

		.content-cities {
			margin-top: 50px;
			flex-direction: column-reverse;

			.cities-image {
				margin-top: 50px;
				min-width: auto;
				max-width: 360px;
				width: 100%;
			}

			.cities-description {
				padding-left: 0px;
				text-align: center;
			}
		}
	}

	div.section-footer {
		padding-top: 40px;

		.footer-flexbox {
			flex-direction: column;
			align-items: center;
			text-align: center;

			.flexbox-service {
				margin-top: 30px;

				.phone {
					font-size: 18px;
					justify-content: center;
				}
			}
		}
	}
}

@media (max-width: @screen-xs-max) {
	div.section-header {
		.navbar {
			.container {
				display: block;
			}

			.navbar-collapse {
				padding-left: 15px;
				padding-right: 15px;
				border: none;
				box-shadow: none;

				.navbar-nav {
					li {
						a {
							padding: 10px 15px;
							border-top: 1px solid @gray-lighter;
						}

						&:first-child {
							a {
								border-top: none;
							}
						}

						&.dropdown {
							.dropdown-menu {
								margin: 0;

								li {
									a {
										border-top: none;
										font-size: 12px;
										padding: 7px 15px;
									}
								}
							}
						}
					}
				}
			}
		}
	}

	div.section-main-carousel {
		.owl-carousel {
			.owl-controls {
				.owl-dots {
					padding: 0;
				}
			}
		}

		.item-inner {
			.inner-image {
				display: none;
			}

			.inner-description {
				width: 100%;

				.heading {
					font-size: 24px;

					strong {
						font-size: 32px;
					}
				}

				.description-service {
					margin-top: 30px;

					h2 {
						font-size: 24px;

						a {
							padding: 20px;
						}
					}
				}
			}
		}
	}

	div.section-offer {
        padding: 50px 0;

		.offer-flexbox {
			flex-direction: column;
			align-items: center;

			.flexbox-service {
				margin-top: 60px;
				width: 100%;
				max-width: 400px;

				.service-image {
					max-width: 300px;
					margin: 0 auto;
				}

				.service-description {
					h2 {
						font-size: 22px;
					}
				}

				&:first-child {
					margin-top: 0;
				}
			}
		}
    }
    
    .section-offer-cta {

        .cta-wrapper {
            flex-direction: column;
            align-items: center;
        }

        .cta-description {
            margin-top: 40px;
            order: 2;
            width: 100%;
            text-align: center;

            h2 {
                font-size: 24px;
    
                span {
                    font-size: 15px;
                }
            }
        }
    
        .cta-image {
            width: 80%;
            height: 250px;
        }
    }

	div.okna-pcv-section-content, div.aluminium-section-content {
		.content-offer {
			ul {
				width: 100%;

				li {
					&:first-child {
						padding: 10px;
						background: @gray;
						border-radius: 4px;

						p {
							margin-top: 0;
							color: #fff;
						}
					}
				}
			}
		}
	}

	.window-offer {
		display: flex;
		justify-content: space-between;

		ul {
			margin-top: 30px;
			text-align: center;

			li {
				&:first-child {
					padding: 10px;
					background: @gray;
					border-radius: 4px;

					p {
						margin-top: 0;
						color: #fff;
					}
				}
			}
		}
	}

	div.oferta-section-content {
		.content-heading {
			h2 {
				font-size: 30px;
			}
        }
        
        .content-others {
            .others-flexbox {
                flex-direction: column;
                align-items: center;
            }
        }

		.content-flexbox {
			flex-direction: column;
			align-items: center;

			.flexbox-image {
				width: 100%;
				max-width: 150px;
			}

			.flexbox-description {
				margin-top: 30px;
				width: 100%;
				text-align: center;

				.heading {
					font-size: 24px;

					strong {
						font-size: 32px;
					}
				}

				ul {
					li {
						.stars {
							display: flex;
							justify-content: center;
						}
					}
				}
			}
		}

		.window-offer {
			ul {
				width: 100%;
			}
		}
	}

	div.static-page-section-content {
		&.o-firmie {
			.logo-flexbox {
				flex-direction: column;

				.flexbox-service {
					width: 100%;
					max-width: 150px;
				}
			}
		}
	}
}

@media (max-width: 600px) {
	div.section-about {
		padding-top: 0;

		.anniversary-flexbox {
			flex-direction: column;

			.flexbox-image {
				width: 150px;

				p {
					top: 25%;
				}
			}

			.flexbox-description {
				margin-top: 20px;
				width: 100%;
				max-width: 400px;
				padding-left: 0;
				text-align: center;

				h2 {
					margin-bottom: 20px;
					font-size: 24px;

					strong {
						font-size: 32px;
					}
				}
			}
		}

		.about-flexbox {
			.flexbox-description {
				text-align: center;

				h2 {
					font-size: 24px;
					margin-bottom: 20px;

					strong {
						font-size: 32px;
					}
				}
			}
		}
    }
    
    div.section-offer-element {
        padding: 50px 0;
        margin-bottom: 20px;
    
        &.bg-light {
            .offer-flexbox {
                flex-direction: column;
                align-items: center;
            }
        }
    
        .offer-flexbox {
            flex-direction: column;
            align-items: center;
    
            .service-image {
                width: 80%;
    
                img {
                    height: 250px;
                }
            }
    
            .service-description {
                margin-top: 30px;
                width: 100%;
                text-align: center;
            }
        }   
    }

	.certifications-flexbox {
		.circle-image {
			&.image-lg {
				height: 100px;
			}

			&.image-md {
				height: 100px;
			}

			&.image-sm {
				height: 100px;
			}
		}
	}
}

@media (max-width: 500px) {
	.news-flexbox {
		align-items: center;
		flex-direction: column;

		.flexbox-service {
			width: 100%;
			max-width: 360px;
		}
	}

	div.static-page-section-content {
		.content-flexbox {
			.flexbox-body {
				.image-gallery {
					.gallery-service {
						width: 100%;
					}
				}
			}
        }
        
        .section-cta {
            padding-top: 60px;
    
            h2 {
                font-size: 26px;
                line-height: 36px;
                span {
                    font-size: 18px;
                    color: @color1;
                }
            }
        }

		&.aktualnosc {
			.main-image {
				width: 100%;
				border-radius: 8px;
			}

			.ck-editor-text {
				margin-top: 40px;
			}
		}
    }
    
       
    .section-offer-cta {
        .cta-description {
            h2 {
                font-size: 18px;
                line-height: 28px;
    
                span {
                    font-size: 12px;
                }
            }
        }
    
        .cta-image {
            height: 200px;
        }
    }
}

@media (max-width: 360px) {
	.certifications-flexbox {
		.circle-image {
			&.image-lg {
				height: 150px;
				width: 100%;
			}

			&.image-md {
				height: 150px;
				width: 100%;
			}

			&.image-sm {
				height: 150px;
				width: 100%;
			}
		}
	}
}